<!-- FeedbackCarousel.vue -->
<template>
    <div class="relative w-full px-4 py-8">
      <h2 class="text-3xl font-bold mb-6 dark:text-white">Client Feedback</h2>
      
      <!-- Navigation Buttons -->
      <button
        v-show="showLeftButton"
        @click="scroll('left')"
        class="absolute left-0 top-1/2 z-10 p-2 rounded-full bg-indigo-600 text-white shadow-lg hover:bg-indigo-700 transition-colors"
        aria-label="Scroll left"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M15 18l-6-6 6-6" />
        </svg>
      </button>
      
      <button
        v-show="showRightButton"
        @click="scroll('right')"
        class="absolute right-0 top-1/2 z-10 p-2 rounded-full bg-indigo-600 text-white shadow-lg hover:bg-indigo-700 transition-colors"
        aria-label="Scroll right"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M9 18l6-6-6-6" />
        </svg>
      </button>
  
      <!-- Feedback Cards Container -->
      <div
        ref="scrollRef"
        class="flex gap-6 overflow-x-auto hide-scrollbar scroll-smooth"
        style="scrollbar-width: none; -ms-overflow-style: none"
        @scroll="updateScrollButtons"
      >
        <div
          v-for="feedback in feedbacks"
          :key="feedback.id"
          class="flex-shrink-0 w-80 p-6 rounded-lg shadow-lg transition-all duration-300 hover:transform hover:scale-105 dark:bg-gray-800 bg-white"
        >
          <div class="flex flex-col h-full">
            <div class="flex items-center mb-4">
              <div 
                class="w-12 h-12 rounded-full bg-indigo-600 flex items-center justify-center text-white font-bold text-xl"
              >
                {{ feedback.name.charAt(0) }}
              </div>
              <div class="ml-4">
                <h3 class="font-semibold dark:text-white">{{ feedback.name }}</h3>
                <p class="text-sm text-gray-600 dark:text-gray-400">{{ feedback.role }}</p>
              </div>
            </div>
            
            <p class="text-gray-700 dark:text-gray-300 flex-grow">
              "{{ feedback.message }}"
            </p>
            
            <div class="flex mt-4">
              <svg
                v-for="star in feedback.rating"
                :key="star"
                class="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  
  // Sample feedback data
  const feedbacks = [
    {
      id: 1,
      name: "Sarah Johnson",
      role: "Project Manager",
      message: "Outstanding work on our web application. The attention to detail was impressive.",
      rating: 5
    },
    {
      id: 2,
      name: "David Chen",
      role: "Tech Lead",
      message: "Great collaboration and excellent technical skills. Delivered ahead of schedule.",
      rating: 5
    },
    {
      id: 3,
      name: "Emma Williams",
      role: "UX Designer",
      message: "Exceptional understanding of user experience principles and implementation.",
      rating: 5
    }
  ]
  
  const scrollRef = ref(null)
  const showLeftButton = ref(false)
  const showRightButton = ref(true)
  
  const updateScrollButtons = () => {
    if (scrollRef.value) {
      showLeftButton.value = scrollRef.value.scrollLeft > 0
      showRightButton.value = 
        scrollRef.value.scrollLeft < 
        scrollRef.value.scrollWidth - scrollRef.value.clientWidth - 10
    }
  }
  
  const scroll = (direction) => {
    if (scrollRef.value) {
      const scrollAmount = 320 // Width of card + gap
      const scrollLeft = direction === 'left' ? -scrollAmount : scrollAmount
      
      scrollRef.value.scrollBy({
        left: scrollLeft,
        behavior: 'smooth'
      })
  
      // Update button visibility after scroll
      setTimeout(updateScrollButtons, 300)
    }
  }
  
  onMounted(() => {
    updateScrollButtons()
  })
  </script>
  
  <style scoped>
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  </style>