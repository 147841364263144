import axios from "axios";
if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:3000/api";
} else {
  axios.defaults.baseURL = "https://portfolio-backend-mm8n.onrender.com/api";
}

// console.log("baseURL", axios.defaults.baseURL);


export const useFetch = axios.create({
  baseURL: axios.defaults.baseURL,
});
